.login-form {
  width: 100%;
  height: 100vh;
  // background: red;
  .login-top {
    height: calc(100vh - 280px);
    background: #e31837;
    position: relative;
    &::after {
      position: absolute;
      content: "";
      height: 0px;
      width: 0px;
      margin: auto;
      border-top: 20px solid #e31837;
      border-left: 36px solid transparent;
      border-right: 36px solid transparent;
      bottom: -20px;
      z-index: 2;
      left: 50%;
      transform: translate(-50%);
    }
    .login-box {
      text-align: center;
      width: 500px;
      position: absolute;
      bottom: 40px;
      left: 50%;
      transform: translate(-50%, 0);
      .err {
        span {
          display: inline-block;
          width: 367px;
          text-align: left;
        }
      }
      .ant-form-item {
        &:nth-child(3) {
          margin-bottom: 10px;
        }
      }
      .ant-form-item-explain-error {
        color: #ffffff;
      }
      .login-header {
        align-items: center;
        text-align: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
        img {
          width: 170px;
          height: 170px;
        }
        h2 {
          text-align: center;
          font: normal normal bold 32px/49px SVN-Merge;
          letter-spacing: 0.32px;
          color: #ffffff;
          margin: 25px 0;
        }
      }
      .login-input {
        width: 367px;
        height: 54px;
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        opacity: 1;
        border-radius: 10px;
      }
    }
  }
  .login-bottom {
    height: 280px;
    background: #ffc522;
    position: relative;
    .login-box {
      text-align: center;
      width: 500px;
      position: absolute;
      left: 50%;
      top: 66px;
      transform: translate(-50%, 0);
    }
    .login-button-container {
      .login-button {
        background: #e31837 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 9px;
        opacity: 1;
        width: 367px;
        height: 58px;
        outline: none;
        border: none;
        text-align: center;
        font: normal normal bold 16px/24px SVN-Merge;
        letter-spacing: 0.16px;
        color: #ffffff;
        opacity: 1;
      }
    }
  }
}
